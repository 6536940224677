import React from 'react';
import {
  Skeleton,
  GridRow,
  GridCol,
  SkeletonAvatar,
  theme,
  AvatarSize,
} from 'ds4-beta';
import {
  StyledLoadingContainer,
  StyledBodyContainer,
  SkeletonHeader,
  SkeletonMainBody,
  SkeletonBodyTitle,
  SkeletonBodyContent,
  SkeletonHeaderTitle,
  SkeletonCard,
  CardInner,
  SkeletonBodyCard,
  SkeletonBreadCrumb,
  SkeletonLogout,
} from './styles';
const DS4PageLoader = () => (
  <StyledLoadingContainer>
    <StyledBodyContainer>
      <SkeletonBreadCrumb>
        <GridRow alignItems='center' padding={false}>
          <GridCol sm={1}>
            <div>
              <Skeleton width={'100%'} height={theme.space[4]} />
            </div>
          </GridCol>
          <GridCol sm={11} justifySelf='flex-end'>
            <SkeletonLogout>
              <Skeleton width={'200px'} height='32px' borderRadius={16} />
              <Skeleton width={'24px'} height='24px' />
              <SkeletonAvatar size={'small' as AvatarSize} />
            </SkeletonLogout>
          </GridCol>
        </GridRow>
      </SkeletonBreadCrumb>
      <SkeletonMainBody>
        <SkeletonHeader>
          <SkeletonHeaderTitle>
            <Skeleton width={'100%'} height={theme.space[5]} />
          </SkeletonHeaderTitle>
          <SkeletonCard>
            <CardInner>
              <Skeleton width={'230px'} height='20px' />
            </CardInner>
            <CardInner>
              <Skeleton width={'230px'} height='20px' />
              <Skeleton width={'160px'} height='32px' />
            </CardInner>
          </SkeletonCard>
        </SkeletonHeader>

        <SkeletonBodyContent>
          <SkeletonBodyTitle>
            <Skeleton width={'230px'} height='20px' />
          </SkeletonBodyTitle>
          <GridRow padding={false}>
            <GridCol sm={4}>
              <SkeletonBodyCard>
                <GridRow padding={false}>
                  <GridCol sm={3}>
                    <SkeletonAvatar />
                  </GridCol>
                  <GridCol sm={9}>
                    <Skeleton width={'85%'} height={theme.space[5]} />
                    <Skeleton width={'85%'} height={theme.space[5]} />
                  </GridCol>
                </GridRow>
              </SkeletonBodyCard>
            </GridCol>
            <GridCol sm={4}>
              <SkeletonBodyCard>
                <GridRow padding={false}>
                  <GridCol sm={3}>
                    <SkeletonAvatar />
                  </GridCol>
                  <GridCol sm={9}>
                    <Skeleton width={'85%'} height={theme.space[5]} />
                    <Skeleton width={'85%'} height={theme.space[5]} />
                  </GridCol>
                </GridRow>
              </SkeletonBodyCard>
            </GridCol>
            <GridCol sm={4}>
              <SkeletonBodyCard>
                <GridRow padding={false}>
                  <GridCol sm={3}>
                    <SkeletonAvatar />
                  </GridCol>
                  <GridCol sm={9}>
                    <Skeleton width={'85%'} height={theme.space[5]} />
                    <Skeleton width={'85%'} height={theme.space[5]} />
                  </GridCol>
                </GridRow>
              </SkeletonBodyCard>
            </GridCol>
          </GridRow>
        </SkeletonBodyContent>
      </SkeletonMainBody>
    </StyledBodyContainer>
  </StyledLoadingContainer>
);

export default DS4PageLoader;
