import React, { Suspense, lazy } from 'react';
import { AuthProvider } from './hooks/auth';
import { FlagProvider } from '@unleash/proxy-client-react';
import { CopilotUIProvider } from 'ds4-beta';
import DS4PageLoader from './DS4Pages/Loader/page-loader';

const App = lazy(() => import('./DS4Pages/App'));

const config = {
  url: process.env.UNLEASH_PROXY_URL ?? '',
  clientKey: process.env.UNLEASH_CLIENT_KEY ?? '',
  appName: 'copilot-admin',
  disableRefresh: true,
  context: {
    userId: window.sessionStorage.getItem('accountId') ?? '',
  },
};

const Root = () => (
  <Suspense fallback={<></>}>
    <FlagProvider config={config}>
      <AuthProvider>
        <CopilotUIProvider>
          <Suspense fallback={<DS4PageLoader />}>
            <App />
          </Suspense>
        </CopilotUIProvider>
      </AuthProvider>
    </FlagProvider>
  </Suspense>
);
export default Root;
